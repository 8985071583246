body {
    font-family: "Nunito Sans", sans-serif;
    box-sizing: border-box;
}
@media (max-width: 768px) {
    body {
        /* font-family: "Nunito Sans 10pt-Bold"; */
         font-family:"Helvetica";
    }
}

::-webkit-scrollbar {
    width: 0;
    height: 0;
    scrollbar-width: none;
}
