.frame {
  background-color: #ffffff;
  border: 8px solid;
  border-color: #d2cefd1a;
  border-radius: 40px;
  height: 457px;
  overflow: hidden;
  position: relative;
  width: 1352px;
}

.frame .div {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 80px;
  position: absolute;
  top: 76px;
}

.frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.frame .get-started-for-free {
  color: var(--text-color);
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 52px;
  font-weight: 700;
  letter-spacing: -0.26px;
  line-height: 52px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .transforming-blank {
  color: #4b4b4e;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.09px;
  line-height: 25.2px;
  position: relative;
  width: 515px;
}

.frame .div-3 {
  align-items: flex-start;
  /* display: inline-flex; */
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.frame .div-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 48px;
  position: relative;
}

.frame .text-wrapper {
  color: #ffffff;
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-wrapper-2 {
  align-items: center;
  background-color: #e8e6fd;
  border: 1.5px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 20px 56px;
  position: relative;
  left:2rem;
}

.frame .text-wrapper-2 {
  color: var(--primary);
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-4 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  left: 779px;
  position: absolute;
  top: 132px;
}

.frame .div-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.frame .group {
  background-color: #17c617;
  border-radius: 5px;
  /* filter: blur(100px); */
  height: 10px;
  position: relative;
  width: 10px;
}

.frame .ellipse {
  background-color: #18c718;
  border-radius: 5px;
  height: 10px;
}

.frame .text-wrapper-3 {
  color: #4b4b4e;
  font-family: "Cerebri Sans Pro-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.09px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 23px;
  position: relative;
}

.frame .checkbox-circle-fill {
  height: 24px;
  position: relative;
  width: 24px;
}

.frame .vector {
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.frame .text-wrapper-4 {
  color: #4b4b4e;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.09px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
@media (min-width: 280px) and (max-width: 912px) {
.frame {
  background-color: #ffffff;
  border: 8px solid;
  border-color: #d2cefd1a;
  border-radius: 20px;
  height: 559px;
  overflow: hidden;
  position: relative;
  width: 100%;

}

.frame .div {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 40px;
  left: 10%;
  position: relative;
  top: 48px;
}

.frame .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.frame .get-started-for-free {
  color: var(--text-color);
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.16px;
  line-height: 32px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.frame .text-wrapper {
  color: #ffffff;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 500px;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  position: relative;
  width: 364px;
}

.frame .transforming-blank {
width:70%;
}
.frame .div-3 {
  align-items: flex-start;
  /* display: inline-flex; */
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
margin-top: 130px;
}

.frame .div-4 {
  align-items: center;
  /* display: inline-flex; */
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  /* position: relative; */
  left:10%;
  top:280px;
}

.frame .group {
  background-color: #17c617;
  border-radius: 5px;
  /* filter: blur(100px); */
  height: 12px;
  position: relative;
  width: 12px;
  left:-4%;
}

.frame .ellipse {
  background-color: #18c718;
  border-radius: 5px;
  height: 10px;
}

.frame .text-wrapper-2 {
  /* color: #4b4b4e; */
  font-family: "Cerebri Sans Pro-Medium", Helvetica;
  font-size: 18px;
  font-weight: 500px;
  letter-spacing: -0.09px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 8px;
  position: relative;
}

.frame .checkbox-circle-fill {
  height: 24px;
  position: relative;
  width: 24px;
}

.frame .vector {
  height: 20px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 20px;
}

.frame .text-wrapper-3 {
  color: black;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 18px;
  font-weight: 400px;
  letter-spacing: -0.09px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.frame .div-6 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  justify-content: center;
  position: relative;
  color:#4b4b4e;
}

.frame .div-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 18px 32px;
  position: relative;
  width: 30%;

}

.frame .text-wrapper-4 {
  /* color: #ffffff; */
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  /* font-size: 16px; */
  /* font-weight: 700; */
  letter-spacing: -0.08px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  color:#4b4b4e;
}

.frame .div-wrapper-2 {
  align-items: center;
  background-color: #e8e6fd;
  border: 1.5px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 16px 50px;
  position: relative;
  left:2rem;
  width:30%;
}

.frame .text-wrapper-5 {
  color: var(--primary);
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

}