.landing-page {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.landing-page .div {
  background-color: #ffffff;
  height: 1039px;
  position: relative;
  width: 1512px;
}

.landing-page .bottom {
  height: 567px;
  left: 0;
  position: absolute;
  top: 1072px;
  width: 1512px;
}

.landing-page .footer-section {
  background-color: #0d0c0d;
  height: 567px;
  position: relative;
}

.landing-page .frame {
  align-items: flex-start;
  display: inline-flex;
  gap: 58px;
  left: 532px;
  position: absolute;
  top: 136px;
  border:2px solid;
  border-color: "transparent";
}

.landing-page .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.landing-page .text-wrapper {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  position: relative;
}

.landing-page .text-wrapper-2 {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .text-wrapper-3 {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.landing-page .mail-outline {
  height: 20px;
  position: relative;
  width: 20px;
}

.landing-page .img {
  height: 24px;
  position: relative;
  width: 24px;
}

.landing-page .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19px;
  position: relative;
}

.landing-page .frame-6 {
  flex: 0 0 auto;
  margin-left: -4px;
  margin-top: -4px;
  position: relative;
}

.landing-page .frame-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.landing-page .text-wrapper-4 {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  width: 227px;
}

.landing-page .p {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Light", Helvetica;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 25.6px;
  position: relative;
  width: 407px;
}

.landing-page .line {
  height: 1px;
  left: 0;
  position: absolute;
  top: 456px;
  width: 1512px;
}

.landing-page .div-2 {
  height: 24px;
  left: 1276px;
  position: absolute;
  top: 495px;
  width: 156px;
}

.landing-page .frame-8 {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 80px;
  position: absolute;
  top: 489px;
}

.landing-page .group {
  height: 38.96px;
  margin-bottom: -2px;
  margin-left: -2px;
  margin-right: -2px;
  margin-top: -2px;
  position: relative;
  width: 36px;
}

.landing-page .text-wrapper-5 {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 12px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
  left: 80px;
  position: absolute;
  top: 136px;
}

.landing-page .text-wrapper-6 {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 291px;
}

.landing-page .text-wrapper-7 {
  color: #fcfcfc;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  position: relative;
  width: 378px;
}

.landing-page .frame-wrapper {
  align-items: center;
  background-color: #ecebfe;
  border: 1px solid;
  border-color: var(--primary);
  border-radius: 8px;
  display: flex;
  gap: 8px;
  height: 64px;
  padding: 8px 16px;
  position: relative;
  width: 349px;
}

.landing-page .frame-10 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 103px;
  position: relative;
}

.landing-page .text-wrapper-8 {
  color: #776cf8;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .div-wrapper {
  align-items: center;
  background-color: var(--primary);
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 8px 14px;
  position: relative;
}

.landing-page .text-wrapper-9 {
  color: #ffffff;
  font-family: "Cerebri Sans Pro-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-11 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 64px;
  left: 35%;
  position: absolute;
  top: 160px;
}

.landing-page .frame-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 32px; */
  justify-content: center;
  position: relative;
}

.landing-page .text-wrapper-10 {
  color: #0a0a0a;
  font-family: "Cerebri Sans Pro-SemiBold", Helvetica;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -0.24px;
  line-height: 67.2px;
  margin-top: -60px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .text-wrapper-11 {
  color: #999999;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.09px;
  line-height: 25.2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-13 {
  background-color: #f8f8f8;
  border-radius: 32px;
  box-shadow: 4px 4px 20px #0000001a;
  height: 608px;
  margin-bottom: -2px;
  margin-left: -2px;
  margin-right: -2px;
  overflow: hidden;
  position: relative;
  width: 436px;
  top:-40px;
}


.landing-page .frame-14 {
  align-items: center;
  background-color: var(--primary);
  border-radius: 32px 32px 0px 0px;
  display: flex;
  gap: 8px;
  justify-content: center;
  left: 0;
  padding: 32px;
  position: absolute;
  top: 0;
  width: 436px;
}

.landing-page .text-wrapper-12 {
  color: #ffffff;
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.09px;
  line-height: 25.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-15 {
  gap: 60px;
  left: 15%;
  /* position: absolute; */
  top: 117px;
   align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
      align-items: center;

}

.smalltext{
  /* font-family: "cer"; */
font-size: 14px;
line-height: 19.6px;
letter-spacing: 0.07px;
}

.landing-page .frame-16 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  /* gap: 40px; */
  position: relative;
}

.landing-page .text-wrapper-13 {
  color: #63636b;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 226px;
}

.landing-page .text-wrapper-14 {
  color: #0a0a0a;
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: -0.24px;
  line-height: 67.2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-17 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 50px;
  box-shadow: 5px 6px 0px #0a0a0a;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  padding: 22px 111px;
  position: relative;
  width: 320px;
}

.landing-page .text-wrapper-15 {
  color: #0a0a0a;
  font-family: "Cerebri Sans Pro-Bold", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-18 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.landing-page .text-wrapper-16 {
  color: #333332;
  font-family: "Cerebri Sans Pro-SemiBold", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .frame-19 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.landing-page .frame-20 {
  height: 14px;
  position: relative;
  width: 14px;
}

.landing-page .text-wrapper-17 {
  color: #63636b;
  font-family: "Cerebri Sans Pro-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.08px;
  line-height: 22.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.landing-page .nav-bar {
  height: 93px;
  left: 0;
  position: fixed;
  top: 0;
  width: 1512px;
}


@media (min-width: 280px) and (max-width: 712px) {

.landing-page .frame-11 {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    gap: 64px;
    left: 5%;
    position: absolute;
    top: 160px;
     width: 90%;

}
}
@media (min-width: 712px) and (max-width: 912px) {
.landing-page .frame-11 {
  left: 20%;
}
}

@media (min-width: 913px) and (max-width: 1012px) {

.landing-page .frame-11 {
  left: 25%;
}
}
