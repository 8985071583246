:root {
    --primary: #1a0ad7;
}

.frameh3 {
    border-top: 4px solid #1a0ad7;
    align-items: flex-start;
    background-color: #fff;
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    padding: 1.5rem 1.25rem;
    width: 35.875rem;
    height: 25.875rem;
}

.text-wrapperh4 {
    color: var(--Primary, #1a0ad7);
    font-family: Nunito Sans 10pt;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: 140%; /* 3.5rem */
    letter-spacing: -0.0125rem;
    width: 31.375rem;
}

.div-request {
    align-items: center;
    background-color: var(--primary);
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 20px 40px;
    position: relative;
}
.text-request-2 {
    color: #ffffff;
    font-family: "Nunito Sans 10pt-Bold", Helvetica;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: -0.08px;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}
@media only screen and (max-width: 800px) {
    .frameh3 {
        width: 22rem;
        height: 20.56rem;
        margin-top: 5rem;
        font-size: 1rem;
    }

    .text-wrapperh4 {
        width: 20rem;
        height: 24rem;
        margin-bottom: 4rem;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 900;
        line-height: 140%;
        letter-spacing: -0.00875rem;
    }
    .div-request {
        margin-top: -4rem;
    }
}
